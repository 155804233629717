import React from 'react';
import './App.css';
import attorneyPhoto from './maria.kalmanovich_cropped.jpg';

function App() {
  return (
    <div className="App">
      <header className="bg-primary text-white text-center py-5 mb-4">
        <div className="container">
          <h1 className="font-weight-light">MAK Legal Services</h1>
        </div>
      </header>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-8 mb-4">
            <div className="card h-100 text-center shadow-lg">
              <img src={attorneyPhoto} className="card-img-top rounded-circle mt-4" alt="Attorney" style={{ width: '150px', height: '150px', margin: '0 auto' }} />
              <div className="card-body">
                <h4 className="card-title">Maria Kalmanovich, Esq.</h4>
                <h6 className="card-subtitle mb-2 text-muted">Immigration Attorney</h6>
                <p className="card-text mt-3">
                  Bar Profile: <a href="https://apps.calbar.ca.gov/attorney/Licensee/Detail/303621" target="_blank" rel="noopener noreferrer" className="btn btn-outline-primary">California Bar</a>
                </p>
                <p className="card-text mt-3">
                  Email: <a href="mailto:GetLaw4U@gmail.com" className="btn btn-outline-secondary">GetLaw4U@gmail.com</a>
                </p>
                <p className="card-text mt-3">
                  Phone: <a href="tel:+13103630991" className="btn btn-outline-secondary">310-363-0991</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="bg-light py-4 mt-5">
        <div className="container text-center">
          <p className="text-muted mb-0">&copy; 2024 Maria Kalmanovich. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
}

export default App;

// npm run buildQueries
// aws s3 cp ./build s3://maria.kalmanovich.com --recursive --metadata-directive REPLACE --cache-control "no-cache"